import React from "react"
import Layout from "../../templates/layout"
import SEO from "../../components/seo/seo"
import Hero from "../../components/hero/hero"
import HaveQuestions from "../../components/haveQuestions/haveQuestions"
import Button from "../../components/button/button"
import BrandLogo from "../../images/logos/elitek-logo.svg"
import QuestionsIcon from "../../images/icons/icon-questions-elitek.svg"
import HeroIcon from "../../images/icons/icon-enroll-elitek-hero.svg"
import speak from '../../images/icons/icon-ELITEK_Speak_with_doctor.png'
import talk from '../../images/icons/icon-ELITEK_Care_Manager.png'
import BackToTop from "../../components/backToTop/BackToTop"
import style from './enrollment.module.scss'
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo"
import AssistCallout from "../../components/assistCallout/AssistCallout"
import rxIcon from '../../images/icons/icon-rx-elitek.svg'
import CircleCta from "../../components/circleCta/circleCta"
import LinkCard from "../../components/linkCard/linkCard"

const pageContent = () => (
  <Layout pageid="page-enrollment">
    <SEO
      title="Patient enrollment with CareASSIST for ELITEK® (rasburicase) | Patient Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), Patient Site, Enrollment"
      description="Ready to enroll? Learn how to enroll with your doctor here. See Prescribing Info, including Boxed WARNING"
    />

    <Hero
      headline="Enrollment "
      copy="Our Care Managers can help you throughout this process. Once your enrollment is complete, they will reach out with next steps"
      brandLogo={BrandLogo}
      brandAlt="Elitek Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Enrollment icon"
      btnLink="../../pdfs/careassist-program-enrollment-form.pdf"
      hasButton={false}
      minHeight="h-452"
    // btnText="Enrollment Form"
    // btnClass="red"
    />

    <div className={style.containerColor + " color-block"}>
      <h2 className="text-center mb-2">There are two ways you can get started with enrollment</h2>
      <div className={style.flexContainer}>
        {/* <div className={style.flexItem}>
          <img src={monitor} alt="monitor icon" />
          <h4> Enroll online</h4>
          <p>Your doctor's office initiates and complete most of this process. You will receive a link via text or email to provide your authorization.</p>
        </div> */}
        <LinkCard
          img={speak}
          alt="Doctor contact icon"          
          cardText="Talk to your doctor"
          cardLink=""
          cardClass="patient enrollment"
          linkID="financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          cardTitle=""
        />
        <p className={style.or + " bold mr-16"}>OR</p>
        {/* <div className={style.flexItem}>
          <img src={fax} alt="fax icon" />
          <h4> Fax or mail</h4>
          <p>Fill out the form with your doctor's office and send it to us by fax.</p>
          <div className={style.enrollButton}>
            <Button className="red" text="Enrollment form" url="#" />
          </div>
        </div> */}
        <LinkCard
          img={talk}
          alt="Care Manager contact icon"          
          cardTitle=""
          cardText="Speak with a Care Manager"
          cardLink=""
          cardClass="red static patient enrollment"
          linkID="financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          btnTitle=""
          btnLink=""

        />
      </div>
    </div>
    <section className="content-section">
      <main>
        <div className={`main-content ${style.assistContainer}`}>
          <AssistCallout linkId="patient-elitek-copay-program" imgAlt="Copay card icon" productClass="elitek patient" img={rxIcon} content="Apply directly to our Copay Program" hasArrow={true} hasGoBtn={true} link="https://portal.trialcard.com/sanofi/careassist/" additionalClass="bold elitek"/>
        </div>

      </main>
      <PrescribingInfo linkId="patient-elitek-pi" link="https://products.sanofi.us/elitek/elitek.html" />
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container"> 

      <BackToTop />
    </div>
  </Layout>
);

export default pageContent